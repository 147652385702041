<template>
  <BaseReport :header_visible="header_visible" :header_buttons="header_buttons" :header_switches="header_switches"
              :app="app" :model="model" :report_name="report_name" :filters="filters" :sorting="sorting"
              :allow_add="allow_add" :header_sw_history="true"
  />
</template>

<script>
import {defineComponent, } from 'vue';
import BaseReport from "@/components/Layout/Report";


export default defineComponent({
  name: 'Balance Sheet',
  components: {
    BaseReport,
  },



  setup() {
    const app='gnokCalendar'
    const model='events'
    const report_name='BalanceSheet'
    const filters={}
    const sorting={}
    const allow_add=false
    const header_visible=true
    const header_buttons=[]
    const header_switches=[]


    return{
      app,model,report_name,filters,sorting,allow_add,header_visible,header_buttons,header_switches,
    }
  }
})

</script>

<style scoped>

</style>